<template>
  <div class="fan">
    <title-top>我的粉丝</title-top>

    <div class="column fansbg mb20">
      <div class="row just-center mt60 mb20">
        <div class="row align-center position_r br15 bg-white search">
          <i class="shuxian"></i>
          <input
            class="font28 f-999999 br15 bg-white position_a search-box"
            v-model="mobile"
            type="text"
            placeholder="搜索个人粉丝用户"
          />
          <div class="column position_a flex-center searchbg">
            <img class="searchbtn" src="~images/sousuo2.png" @click="onSearch" />
          </div>
        </div>
      </div>
      <div class="row flex-center" @click="onLook">
        <span class="font24 f-FFFFFF">查看我的邀请人</span>
        <img class="imgsize32" src="~images/nextrw.png" alt />
      </div>
    </div>
    <div v-for="(item,index) in list" :key="index">
      <div class="row between align-center bg-white h146">
        <div class="row align-center ml25">
          <img class="imgsize96 br100 mr25" :src="item.avatar.url" alt />
          <div class="column ml25">
            <span class="font28 f-1A1A1A fontweight500">{{item.name}}</span>
            <span class="font24 f-666666">账号：13964872408</span>
          </div>
        </div>
        <span class="font24 f-666666 mr25">{{item.created_at | formatYear}}</span>
      </div>
      <div class="line"></div>
    </div>
    <div
      v-if="false"
      class="row flex-center font28 f-FFFFFF bg-FF8E61 h80 w100 position_f"
      style="bottom: 0;max-width: 750px;"
    >专属粉丝：{{counts}}</div>
    <div class="column flex-center model-mask" v-if="isShow">
      <div class="row whitescreen between align-center size">
        <div class="row align-center">
          <img class="toux imgsize88 br50 ml25 mr25" :src="res.avatar.url" alt />
          <div class="column">
            <span class="font32 f-1A1A1A">{{res.name}}</span>
            <span class="font24 f-999999">账号：{{res.mobile}}</span>
          </div>
        </div>
        <img class="toux imgsize44 mr25" src alt @click="onClose" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { downlines, downlines_search, inviter } from "network/igou";
import { formatDate } from "common/util";
export default {
  name: "MyFan",
  data() {
    return {
      list: [],
      mobile: "",
      res: {},
      counts: 0,
      isShow: false
    }
  },
  filters: {
    formatYear(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    }
  },
  mounted() {
    this.get_downlines();
  },
  methods: {
    onClose() {
      this.isShow = false;
    },
    onLook() {
      this.get_inviter();
    },
    onSearch() {
      this.get_downlines_search();
    },
    get_downlines() {
      downlines(1, 10000).then(res => {
        this.list = res.data;
        this.counts = res.counts;
      });
    },
    get_downlines_search() {
      if (!this.mobile) {
        this.$toast.show("请输入搜索人信息");
      }
      downlines_search(this.mobile).then(res => {
        this.list = res.data;
      });
    },
    get_inviter() {
      inviter().then(res => {
        this.isShow = true;
        this.res = res.data;
      });
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.fansbg {
  width: 100%;
  height: 4.266667rem;
  background: url(~images/tixianbg.png) no-repeat;
  background-size: 100% 5.6rem;
}
.search {
  box-sizing: border-box;
  border: 0.013333rem solid #f62341;
  width: 8.133333rem;
  height: 0.826667rem;
}
.shuxian {
  position: absolute;
  left: 0.373333rem;
  top: 0.213333rem;
  height: 0.373333rem;
  width: 0.026667rem;
  background-color: #f62341;
}
.search-box {
  box-sizing: border-box;
  left: 0.6rem;
  border: none;
  outline: none;
  cursor: pointer;
  width: 6rem;
  height: 0.746667rem;
}
.searchbg {
  right: 0;
}
.searchbtn {
  width: 1.306667rem;
  height: 0.8rem;
}
.size {
  width: 8.666667rem;
  height: 2rem;
}
.toux {
  background-color: yellow;
}
</style>
